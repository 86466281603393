import React from "react"

import { iconEmail, iconLinkedin, iconGithub, iconMedium, iconTwitter } from '../util/svgs.js'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__contact">
        <a className="icon--small" href="https://github.com/Roi-Livne" >
          {iconGithub()}
        </a>
        <a className="icon--small" href="mailto:roilivne14@gmail.com">
          {iconEmail()}
        </a>
        <a className="icon--small" href="https://www.linkedin.com/in/roi-livne/">
          {iconLinkedin()}
        </a>
        <a className="icon--small" href="https://roilivne14.medium.com/">
          {iconMedium()}
        </a>
        <a className="icon--small icon--alt" href="https://twitter.com/LivneRoi">
          {iconTwitter()}
        </a>
      </div>
      <p>&#169; All rights reserved 2021 | Roi Livne</p>
    </footer>
  )
}

export default Footer
