import React from "react"

export const iconEmail = () => <svg xmlns="http://www.w3.org/2000/svg" width="76" height="62" viewBox="0 0 76 62">
  <path id="Path_7" data-name="Path 7" d="M78,11.75A7.7,7.7,0,0,0,70.4,4H9.6A7.7,7.7,0,0,0,2,11.75v46.5A7.7,7.7,0,0,0,9.6,66H70.4A7.7,7.7,0,0,0,78,58.25Zm-7.6,0L40,31.125,9.6,11.75Zm0,46.5H9.6V19.5L40,38.875,70.4,19.5Z" transform="translate(-2 -4)" fill="#62e0d9" />
</svg>
export const iconGithub = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#62e0d9" viewBox="0 0 24 24"><path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" /></svg>
export const iconLinkedin = () => <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
  <path id="iconmonstr-linkedin-1" d="M16.6,9.7a8.488,8.488,0,0,1-8.267,8.7A8.488,8.488,0,0,1,.067,9.7,8.49,8.49,0,0,1,8.333,1,8.49,8.49,0,0,1,16.6,9.7Zm.067,15.652H0V81H16.667Zm26.607,0H26.713V81H43.277V51.786c0-16.243,20.1-17.572,20.1,0V81H80V45.762c0-27.409-29.74-26.41-36.727-12.918v-7.5Z" transform="translate(0 -1)" fill="#62e0d9" />
</svg>
export const iconMedium = () => <svg xmlns="http://www.w3.org/2000/svg" width="80" height="70" viewBox="0 0 80 70">
  <path id="iconmonstr-medium-1" d="M9.487,17.281a3.6,3.6,0,0,0-1.01-2.88L1.01,4.481V3H24.2L42.13,46.334,57.89,3H80V4.481L73.613,11.23a2.17,2.17,0,0,0-.71,1.977V62.8a2.167,2.167,0,0,0,.71,1.973l6.237,6.749V73H48.477V71.519L54.94,64.6c.633-.7.633-.9.633-1.973V22.549L37.61,72.838H35.183L14.267,22.549v33.7a4.956,4.956,0,0,0,1.157,3.865l8.4,11.235v1.484H0V71.354L8.4,60.119a4.782,4.782,0,0,0,1.083-3.865V17.281Z" transform="translate(0 -3)" fill="#62e0d9" fillRule="evenodd" />
</svg>
export const iconTwitter = () => <svg xmlns="http://www.w3.org/2000/svg" width="80" height="70" viewBox="0 0 80 70">
  <path id="iconmonstr-twitter-1" d="M80,10.248a28.8,28.8,0,0,1-9,3,17.729,17.729,0,0,0,7-10,36.13,36.13,0,0,1-11,5c-2.99-3.435-7.28-6-12-6-10.6,0-18.393,10.949-16,22-13.637-.736-24.9-8.308-33-19-4.3,7.942-2.307,18.739,5,24a17.546,17.546,0,0,1-8-2,16.69,16.69,0,0,0,13,17,13.76,13.76,0,0,1-7,0c2.087,7.02,7.813,12.856,15,13a30.442,30.442,0,0,1-24,7,43.647,43.647,0,0,0,25,8c30.473,0,48.04-28.146,47-53A33.635,33.635,0,0,0,80,10.248Z" transform="translate(0 -2.248)" fill="#62e0d9" />
</svg>
export const iconTools = () => <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#62e0d9"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M21.67,18.17l-5.3-5.3h-0.99l-2.54,2.54v0.99l5.3,5.3c0.39,0.39,1.02,0.39,1.41,0l2.12-2.12 C22.06,19.2,22.06,18.56,21.67,18.17z M18.84,19.59l-4.24-4.24l0.71-0.71l4.24,4.24L18.84,19.59z" /></g><g><path d="M17.34,10.19l1.41-1.41l2.12,2.12c1.17-1.17,1.17-3.07,0-4.24l-3.54-3.54l-1.41,1.41V1.71L15.22,1l-3.54,3.54l0.71,0.71 h2.83l-1.41,1.41l1.06,1.06l-2.89,2.89L7.85,6.48V5.06L4.83,2.04L2,4.87l3.03,3.03h1.41l4.13,4.13l-0.85,0.85H7.6l-5.3,5.3 c-0.39,0.39-0.39,1.02,0,1.41l2.12,2.12c0.39,0.39,1.02,0.39,1.41,0l5.3-5.3v-2.12l5.15-5.15L17.34,10.19z M9.36,15.34 l-4.24,4.24l-0.71-0.71l4.24-4.24l0,0L9.36,15.34L9.36,15.34z" /></g></g></g></svg>
export const iconUser = () => <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#62e0d9"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 8.5c-.91 0-2.75.46-2.75 1.38v4.62h1.5V19h2.5v-4.5h1.5V9.88c0-.91-1.84-1.38-2.75-1.38zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" /><circle cx="12" cy="6.5" r="1.5" /></svg>
export const iconDesign = () => <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#62e0d9"><g><rect fill="none" height="24" width="24" /></g><g><g /><g><path d="M20.97,7.27c0.39-0.39,0.39-1.02,0-1.41l-2.83-2.83c-0.39-0.39-1.02-0.39-1.41,0l-4.49,4.49L8.35,3.63 c-0.78-0.78-2.05-0.78-2.83,0l-1.9,1.9c-0.78,0.78-0.78,2.05,0,2.83l3.89,3.89L3,16.76V21h4.24l4.52-4.52l3.89,3.89 c0.95,0.95,2.23,0.6,2.83,0l1.9-1.9c0.78-0.78,0.78-2.05,0-2.83l-3.89-3.89L20.97,7.27z M5.04,6.94l1.89-1.9c0,0,0,0,0,0 l1.27,1.27L7.02,7.5l1.41,1.41l1.19-1.19l1.2,1.2l-1.9,1.9L5.04,6.94z M16.27,14.38l-1.19,1.19l1.41,1.41l1.19-1.19l1.27,1.27 l-1.9,1.9l-3.89-3.89l1.9-1.9L16.27,14.38z M6.41,19H5v-1.41l9.61-9.61l1.3,1.3l0.11,0.11L6.41,19z M16.02,6.56l1.41-1.41 l1.41,1.41l-1.41,1.41L16.02,6.56z" /></g></g></svg>