import React from "react"
import { Link } from "gatsby"

const Header = () => (
  <header className="header">
    <nav className="header__nav">
      <Link to="/" className="header__nav--button" activeClassName="nav-active">
        <span>Home</span>
      </Link>
      <div className="header__nav--right">
        <Link
          to="/blog"
          className="header__nav--button"
          activeClassName="nav-active"
        >
          <span>Blog</span>
        </Link>
      </div>
    </nav>
  </header>
)

export default Header
